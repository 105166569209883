import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDIB2bktS9kTPkE-Ky0jjxd4a-wq7kJ1TY",
  authDomain: "dill-ar.firebaseapp.com",
  databaseURL: "https://dill-website-communications.firebaseio.com/",
  projectId: "dill-ar",
  storageBucket: "dill-ar.appspot.com",
  messagingSenderId: "812938884911",
  appId: "1:812938884911:web:8365690d3568e5f27b13da",
  measurementId: "G-FYLE2NE6TK"
};

export const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);
export const analytics = getAnalytics(app);