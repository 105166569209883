import React from "react";
import Navigation2 from "../components/Navigation/Navigation2";

import PageCareers from "../components/PageCareers";

const Careers = () => {
  return (
    <div>
      <Navigation2 />
      <PageCareers />
    </div>
  );
};

export default Careers;
