import React, { useEffect, useState } from "react";
import icon1 from "../../assets/images/outdatedIcon.svg";
import icon2 from "../../assets/images/lossIcon.svg";
import icon3 from "../../assets/images/tooManyIcon.svg";
import icon4 from "../../assets/images/thinkIcon.svg";
import icon5 from "../../assets/images/interactionIcon.svg";
import icon6 from "../../assets/images/staffIcon.svg";
import cloud1 from "../../assets/images/cloud-1.png";
import cloud2 from "../../assets/images/cloud-2.png";
import cloud3 from "../../assets/images/cloud-3.png";
import cloud4 from "../../assets/images/cloud-4.png";
import cloud5 from "../../assets/images/cloud-5.png";
import cloud6 from "../../assets/images/cloud-6.png";
import "./Sec2.css";
import { useInView } from "react-intersection-observer";

const Sec2 = (props) => {
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      setTimeout(() => {}, 20);
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  useEffect(() => {
    if (windowSize.innerWidth > 1512) {
      document.documentElement.style.setProperty("--dwp2", `1px`);
    } else {
      document.documentElement.style.setProperty("--dwp2", `(100vw/1512)`);
    }
  }, [windowSize]);

  const { ref: cloud1ref, inView: cloud1IsVisible } = useInView();
  const { ref: cloud2ref, inView: cloud2IsVisible } = useInView();
  const { ref: cloud3ref, inView: cloud3IsVisible } = useInView();
  const { ref: cloud4ref, inView: cloud4IsVisible } = useInView();
  const { ref: cloud5ref, inView: cloud5IsVisible } = useInView();
  const { ref: cloud6ref, inView: cloud6IsVisible } = useInView();

  const { ref: box1ref, inView: box1IsVisible } = useInView();
  const { ref: box2ref, inView: box2IsVisible } = useInView();
  const { ref: box3ref, inView: box3IsVisible } = useInView();
  const { ref: box4ref, inView: box4IsVisible } = useInView();
  const { ref: box5ref, inView: box5IsVisible } = useInView();
  const { ref: box6ref, inView: box6IsVisible } = useInView();

  return (
    <div id={"section2"}>
      <div className="section-features" id="main-sec2">
        <img
          alt=""
          src={cloud1}
          className={`${"cloud1"} ${cloud1IsVisible ? "slide" : ""}`}
          ref={cloud1ref}
        />
        <img
          alt=""
          src={cloud2}
          className={`${"cloud2"} ${cloud2IsVisible ? "slide2" : ""}`}
          ref={cloud2ref}
        />
        <img
          alt=""
          src={cloud3}
          className={`${"cloud3"} ${cloud3IsVisible ? "slide" : ""}`}
          ref={cloud3ref}
        />
        <img
          alt=""
          src={cloud4}
          className={`${"cloud4"} ${cloud4IsVisible ? "slide2" : ""}`}
          ref={cloud4ref}
        />
        <img
          alt=""
          src={cloud5}
          className={`${"cloud5"} ${cloud5IsVisible ? "slide" : ""}`}
          ref={cloud5ref}
        />
        <img
          alt=""
          src={cloud6}
          className={`${"cloud6"} ${cloud6IsVisible ? "slide2" : ""}`}
          ref={cloud6ref}
        />

        <div className="section-features-subcontainer">
          <h3>Let us help if</h3>
          <div className="section-features-subcontainer2">
            <div className="feature-list">
              <div
                id="main-sec2-box1"
                className={`${"mainFeatures-Container"} ${
                  box1IsVisible ? "slide" : ""
                }`}
                ref={box1ref}
              >
                <div className="iconPart-container">
                  <div className="iconPart">
                    <img className="section2-icon1" src={icon1} alt="" />
                  </div>
                </div>
                <div className="featuresContainer2">
                  <div className="headerTitle">
                    <h4>Your tech is outdated</h4>
                  </div>
                  <ul>
                    <li>You've been avoiding the costs.</li>
                  </ul>
                  <ul>
                    <li>
                      You've been avoiding complicated installations, training, adoption.
                    </li>
                  </ul>
                  <ul>
                    <li>
                      You're skeptical of the benefits of a tech upgrade.
                    </li>
                  </ul>
                </div>
              </div>

              <div
                id="main-sec2-box2"
                className={`${"mainFeatures-Container"} ${
                  box2IsVisible ? "slide2" : ""
                }`}
                ref={box2ref}
              >
                <div className="iconPart-container">
                  <div className="iconPart">
                    <img
                      className="section2-icon2"
                      src={icon2}
                      style={{ color: "black" }}
                      alt=""
                    />
                  </div>
                </div>
                <div className="featuresContainer2">
                  <div className="headerTitle">
                    <h4>Your tech eats too much into your profit margins</h4>
                  </div>
                  <ul>
                    <li>
                      You’re probably paying 2.5% - 3.5% for your payment processing. Yikes!
                    </li>
                  </ul>
                  <ul>
                    <li>
                      It all adds up: You're paying a few different tech companies each month.
                    </li>
                  </ul>
                </div>
              </div>

              <div
                id="main-sec2-box3"
                className={`${"mainFeatures-Container"} ${
                  box3IsVisible ? "slide" : ""
                }`}
                ref={box3ref}
              >
                <div className="iconPart-container">
                  <div className="iconPart">
                    <img className="section2-icon3" src={icon3} alt="" />
                  </div>
                </div>
                <div className="featuresContainer2">
                  <div className="headerTitle">
                    <h4>You’ve got too many disparate systems</h4>
                  </div>
                  <ul>
                    <li>They don’t always work together all that well.</li>
                  </ul>
                  <ul>
                    <li style={{ listStyle: "inside" }}>
                      You have to call various customer service lines to try to get them integrated and in the end they probably just blame each other.
                    </li>
                  </ul>
                  <ul>
                    <li>It's cluttering up your space</li>
                  </ul>
                  <ul>
                    <li>
                      There’s a bill for each single one of them every month.
                    </li>
                  </ul>
                  <ul>
                    <li
                      style={{
                        listStylePosition: "inside",
                      }}
                    >
                      You’re covering the sales and marketing, overhead, and profit of multiple tech companies.
                    </li>
                  </ul>
                </div>
              </div>

              <div
                id="main-sec2-box4"
                className={`${"mainFeatures-Container"} ${
                  box4IsVisible ? "slide2" : ""
                }`}
                ref={box4ref}
              >
                <div className="iconPart-container">
                  <div className="iconPart">
                    <img className="section2-icon4" src={icon4} alt="" />
                  </div>
                </div>
                <div className="featuresContainer">
                  <div className="headerTitle">
                    <h4>
                      You have to be in the restaurant to stay on top of things
                    </h4>
                  </div>
                  <ul>
                    <li>
                      Can’t monitor the status of every single aspect of your business in realtime without being there or being on a computer and/or on the phone.
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Can’t control your marketing, dynamic pricing, loyalty, reservations, waitlist, shift scheduling, payments, analytics and accounting on the go.
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Don’t know how you are doing compared to all other restaurants this instance.
                    </li>
                  </ul>
                </div>
              </div>

              <div
                id="main-sec2-box5"
                className={`${"mainFeatures-Container"} ${
                  box5IsVisible ? "slide" : ""
                }`}
                ref={box5ref}
              >
                <div className="iconPart-container">
                  <div className="iconPart">
                    <img className="section2-icon5" src={icon5} alt="" />
                  </div>
                </div>
                <div className="featuresContainer">
                  <div className="headerTitle">
                    <h4>
                      You need more virtual interaction with your customers
                    </h4>
                  </div>
                  <ul>
                    <li>
                      You don’t have a way to reach them when you need them the most.
                    </li>
                  </ul>
                  <ul>
                    <li style={{ listStyle: "inside" }}>
                      You don’t have access to a platform that enables you to proactively engage customers.
                    </li>
                  </ul>
                  <ul>
                    <li style={{ listStyle: "inside" }}>
                      You don’t have a way to dynamically price your menu and instantly inform hungry customers of offers.
                    </li>
                  </ul>
                  <ul>
                    <li>
                      You don’t have a way to find out what their experience was in a meaningful manner.
                    </li>
                  </ul>
                  <ul>
                    <li style={{ listStyle: "inside" }}>
                      You have no way of knowing when the party dined, where they were seated, who cooked their food, who their server was, and what they ate.
                    </li>
                  </ul>
                  <ul>
                    <li style={{ listStyle: "inside" }}>
                      You have to read through paragraphs of reviews without an actionable conclusion.
                    </li>
                  </ul>
                  <ul>
                    <li style={{ listStyle: "inside" }}>
                      You end up not knowing what to fix in order to avoid negative reviews that bring your ratings down and hurt your business.
                    </li>
                  </ul>
                </div>
              </div>

              <div
                id="main-sec2-box6"
                className={`${"mainFeatures-Container"} ${
                  box6IsVisible ? "slide2" : ""
                }`}
                ref={box6ref}
              >
                <div className="iconPart-container">
                  <div className="iconPart">
                    <img className="section2-icon6" src={icon6} alt="" />
                  </div>
                </div>
                <div className="featuresContainer">
                  <div className="headerTitle">
                    <h4>
                      You’re struggling to hire, train, and retain quality staff
                    </h4>
                  </div>
                  <ul>
                    <li>You find that quality staff is harder to come by.</li>
                  </ul>
                  <ul>
                    <li>You're hurting from the higher labor costs.</li>
                  </ul>
                  <ul>
                    <li>You're struggling to keep your performing employees.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sec2;
