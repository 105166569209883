import React, { useEffect, useState } from "react";
import "./Sec1.css";
import Navigation from "../Navigation/Navigation";
import NavigationMobile from "../Navigation/NavigationMobile";
import svg from "../../assets/images/sec1svg.svg";
import svg2 from "../../assets/images/Group151.svg";

const Sec1 = (props) => {
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      setTimeout(() => {}, 20);
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  useEffect(() => {
    if (windowSize.innerHeight > windowSize.innerWidth) {
      document.documentElement.style.setProperty("--dfx", "calc(100vw/1052)");
    } else {
      document.documentElement.style.setProperty("--dfx", "calc(100vh/982)");
    }
  }, [windowSize]);

  return (
    <div id={"header"}>
      <div className="header-top-container">
        {windowSize.innerWidth <= 500 ? (
          <img src={svg2} className="sec1-image" alt="" />
        ) : (
          <img src={svg} className="sec1-image" alt="" />
        )}

        {windowSize.innerWidth <= 500 ? (
          <NavigationMobile stickyNav={props.stickyNav} />
        ) : (
          <Navigation stickyNav={props.stickyNav} />
        )}

        <div className="sub-nav-container">
          <div className="hero-text-box">
            <div className="main-container">
              <div className={"containera"}>
                <p>
                  THE NEXT ERA OF RESTAURANT MANAGEMENT TECHNOLOGY HAS ARRIVED
                </p>
              </div>
              <div className={"header-container2"}>
                <p>
                  Live control of your restaurant at your fingertips{'\n'}Peace of mind that follows you wherever you go
                </p>
              </div>
              <div className={"header-container3"}>
                <p>
                  We give restaurant owners live control of marketing, dynamic pricing, loyalty, reservations, waitlist, shift scheduling, payments, analytics and accounting from their phone, aggregating the many disparate systems into one unified solution.
                </p>
              </div>
              <div className={"header-container4"}>
                <p>
                  We will increase your revenue and reduce your costs. Let us show you how.
                </p>
              </div>
              <div className={"header-container5"}>
                <a href="/BookVisit">Schedule Appointment</a>
              </div>
              <div className={"header-container5"}>
                <a href="/#section2">Learn More</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sec1;
