import React, { useEffect } from "react";
import "./Sec2Trans3.css";
import { useInView } from "react-intersection-observer";

import photoOne from "../../assets/images/dillLogo(1).svg";

import photoTwo from "../../assets/images/cloud-6(4).png";

const Sec2Trans3 = (props) => {
  const { ref: cloudyref, inView: cloudyIsVisible } = useInView();
  const { ref: blurryref, inView: blurIsVisible } = useInView();

  useEffect(() => {
    if (cloudyIsVisible) {
    }
  }, [cloudyIsVisible]);
  return (
    <div id={""}>
      <div className="section3-container">
        <div
          ref={blurryref}
          className={`${"section3-blurry"} ${
            blurIsVisible ? "blurryBack" : ""
          }`}
        >
          <img
            alt="sec3-1"
            src={photoTwo}
            ref={cloudyref}
            className={`${"section3-cloud"} ${
              cloudyIsVisible ? "slideClody" : ""
            }`}
          />
        </div>
        <img className="section3-logo" src={photoOne} alt="sec3-2" />
      </div>
    </div>
  );
};

export default Sec2Trans3;
