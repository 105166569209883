import React, { useEffect, useState } from "react";
import "./Sec5.css";
import imageOne from "../../assets/images/sec5.1gfx.svg";
import imageTwo from "../../assets/images/sec5.2gfx.svg";
import imageThree from "../../assets/images/sec5.3gfx.svg";
import imageFour from "../../assets/images/sec5.4gfx.svg";
import imageFive from "../../assets/images/sec5.5gfx.svg";
import imageSix from "../../assets/images/sec5.2gfx(1).svg";
// import imageSeven from "../../assets/images/5.3humans.svg";
import imageEight from "../../assets/images/sec5.5gfx2.svg";
import Sec5Trans6 from "../Sec5Trans6/Sec5Trans6";

const Sec5 = (props) => {
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      setTimeout(() => {}, 20);
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <div id={"joining"}>
      <div className="section5-container">
        {windowSize.innerWidth <= 500 ? (
          <div className="section5-main-container">
            <div className="section5-m-title">
              <h5>Joining dill</h5>
            </div>

            <div className="section5-part1">
              <div className="section5-part1-left">
                <h4>Step 1</h4>
                <h5>Learn more and discuss</h5>
                <ul>
                  <li>The journey starts with a call</li>
                  <li>
                    Schedule a discovery call or a face-to-face visit at your
                    restaurant so we can learn more about the challenges that
                    you are facing.
                  </li>
                </ul>
                <div className="section5-scheduleAp">
                  <a href="/BookVisit">Schedule Appointment</a>
                </div>
                <ul>
                  <li>
                    We’ll also show you how you’d interact with our products and
                    how they’d make your life better. We’ll also answer any
                    questions that you have and discuss next steps.
                  </li>
                </ul>
                <img src={imageOne} className="section5-image1" alt="" />
              </div>
            </div>

            <div className="section5-part2">
              <div className="section5-part2-right">
                <h4>Step 2</h4>
                <h5>Personalize your system and move forward with dill</h5>
                <ul>
                  <li>
                    Our technical sales team member will inquire about your
                    requirements and make suggestions on how you can best
                    leverage the benefits that dill has to offer
                  </li>

                  <li>
                    If you’re curious and you want to try us out you should know
                    there’s no upfront costs and we only ask for a four-week
                    notice cancellation period
                  </li>
                  <li>
                    After we complete our succinct bilateral agreement we put in
                    place a quick plan for the following steps
                  </li>
                </ul>
              </div>
              <div className="section5-part2-left">
                <img className="section5-image2" src={imageSix} alt="" />
              </div>
            </div>

            <div className="section5-part3">
              <div className="section5-part3-left">
                <h4>Step 3</h4>
                <h5>Install and set up</h5>
                <ul>
                  <li>
                    {" "}
                    Our technical sales team member will visit your restaurant
                    at the arranged time to install our proprietary IoT device
                    (it’s tiny and it’s free)
                  </li>
                  <li>
                    They will then assist you in creating your owners, managers,
                    and servers accounts and listing your restaurant on our
                    platform
                  </li>
                  <li>
                    They will also assist you <br /> with data entry such as{" "}
                    <br /> entering your menu items <br /> onto the platform
                  </li>
                </ul>
              </div>
              <div className="section5-part3-right">
                <img className="section5-image3" src={imageThree} alt="" />
              </div>
            </div>

            <div className="section5-part4">
              <div className="section5-part4-right">
                <h4>Step 4</h4>
                <h5>Training and practice runs</h5>
                <ul>
                  <li>
                    {" "}
                    Our technical sales team member will provide training
                    sessions for yourself and your staff to make sure you are
                    maximizing your use of the benefits that dill offers
                  </li>

                  <li>The apps are easy to use and intuitive</li>
                  <li>
                    We will then use all of the features of the app in training
                    mode and when you are ready we will switch you online
                  </li>
                </ul>
              </div>
              <div className="section5-part4-left">
                <img className="section5-image4" src={imageFour} alt="" />
              </div>
            </div>

            <div className="section5-part5">
              <div className="section5-part5-left">
                <h4>Step 5</h4>
                <h5>Congratulations you're all set up!</h5>
                <ul>
                  <li> All of these steps can happen on the same afternoon</li>
                  <li>Start seeing the benefits immediately</li>
                  <li>
                    dill does not interfere with the performance of your current
                    setup in any way. Any issues with dill will simply revert
                    you to your current system seamlessly
                  </li>
                  <li>
                    Our ongoing support and customer check-ins will ensure that
                    you are making the most of our technology
                  </li>
                  <li>
                    At any time if you need a refresher we would gladly come in
                    for more training
                  </li>
                </ul>
              </div>
              <div className="section5-part5-right">
                <img className="section5-image5" src={imageEight} alt="" />
              </div>
            </div>
          </div>
        ) : (
          <div className="section5-main-container">
            <div className="section5-main-subcontainer">
              <div className="section5-part1">
                <div className="section5-part1-left">
                  <h4>Step 1</h4>
                  <h5>Learn more and discuss</h5>
                  <ul>
                    <li>The journey starts with a call</li>
                    <li>
                      Schedule a discovery call or a face-to-face visit at your
                      restaurant so we can learn more about the challenges that
                      you are facing.
                    </li>
                  </ul>
                  <div className="section5-scheduleAp">
                    <a href="/BookVisit">Schedule Appointment</a>
                  </div>
                  <ul>
                    <li>
                      We’ll also show you how you’d interact with our products
                      and how they’d make your life better. We’ll also answer
                      any questions that you have and discuss next steps.
                    </li>
                  </ul>
                </div>
                <div className="section5-part1-right">
                  <img src={imageOne} className="section5-image1" alt="" />
                </div>
              </div>

              <div className="section5-part2">
                <div className="section5-part2-left">
                  <img className="section5-image2" src={imageTwo} alt="" />
                </div>
                <div className="section5-part2-right">
                  <h4>Step 2</h4>
                  <h5>Personalize your system and move forward with dill</h5>
                  <ul>
                    <li>
                      Our technical sales team member will inquire about your
                      requirements and make suggestions on how you can best
                      leverage the benefits that dill has to offer
                    </li>

                    <li>
                      If you’re curious and you want to try us out you should
                      know there’s no upfront costs and we only ask for a
                      four-week notice cancellation period
                    </li>
                    <li>
                      After we complete our succinct bilateral agreement we put
                      in place a quick plan for the following steps
                    </li>
                  </ul>
                </div>
              </div>

              <div className="section5-part3">
                <div className="section5-part3-left">
                  <h4>Step 3</h4>
                  <h5>Install and set up</h5>
                  <ul>
                    <li>
                      {" "}
                      Our technical sales team member will visit your restaurant
                      at the arranged time to install our proprietary IoT device
                      (it’s tiny and it’s free)
                    </li>
                    <li>
                      They will then assist you in creating your owners,
                      managers, and servers accounts and listing your restaurant
                      on our platform
                    </li>
                    <li>
                      They will also assist you with data entry such as entering
                      your menu items onto the platform
                    </li>
                  </ul>
                </div>
                <div className="section5-part3-right">
                  <img className="section5-image3" src={imageThree} alt="" />
                </div>
              </div>

              <div className="section5-part4">
                <div className="section5-part4-left">
                  <img className="section5-image4" src={imageFour} alt="" />
                </div>
                <div className="section5-part4-right">
                  <h4>Step 4</h4>
                  <h5>Training and practice runs</h5>
                  <ul>
                    <li>
                      {" "}
                      Our technical sales team member will provide training
                      sessions for yourself and your staff to make sure you are
                      maximizing your use of the benefits that dill offers
                    </li>

                    <li>The apps are easy to use and intuitive</li>
                    <li>
                      We will then use all of the features of the app in
                      training mode and when you are ready we will switch you
                      online
                    </li>
                  </ul>
                </div>
              </div>

              <div className="section5-part5">
                <div className="section5-part5-left">
                  <h4>Step 5</h4>
                  <h5>Congratulations you're all set up!</h5>
                  <ul>
                    <li>
                      {" "}
                      All of these steps can happen on the same afternoon
                    </li>
                    <li>Start seeing the benefits immediately</li>
                    <li>
                      dill does not interfere with the performance of your
                      current setup in any way. Any issues with dill will simply
                      revert you to your current system seamlessly
                    </li>
                    <li>
                      Our ongoing support and customer check-ins will ensure
                      that you are making the most of our technology
                    </li>
                    <li>
                      At any time if you need a refresher we would gladly come
                      in for more training
                    </li>
                  </ul>
                </div>
                <div className="section5-part5-right">
                  <img className="section5-image5" src={imageFive} alt="" />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="section5to6">
          <Sec5Trans6 />
        </div>
      </div>
    </div>
  );
};

export default Sec5;
