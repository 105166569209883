import React from "react";
import "./Page.css";
import PageRow from "./PageRow";
import { PagesContentArray } from "./content/PagesContentArray";

const PagePrivacy = () => {
  return (
    <div className="Page">
      <div className="PageTitle">{PagesContentArray.privacy.title}</div>
      <div className="PageContentColumn">
        {PagesContentArray.privacy.contentArray.map((id, i) => {
          return (
            <PageRow
              key={PagesContentArray.privacy.contentArray[i].id + i}
              id={PagesContentArray.privacy.contentArray[i].id}
              parentAppPage={PagesContentArray.privacy.appPage}
              hidden={PagesContentArray.privacy.contentArray[i].hidden}
              columnNo={PagesContentArray.privacy.contentArray[i].columnNo}
              form={PagesContentArray.privacy.contentArray[i].form}
              button={PagesContentArray.privacy.contentArray[i].button}
              title={PagesContentArray.privacy.contentArray[i].title}
              link={PagesContentArray.privacy.contentArray[i].link}
              img={PagesContentArray.privacy.contentArray[i].img}
              text={PagesContentArray.privacy.contentArray[i].text}
              option={PagesContentArray.privacy.contentArray[i].option}
              imgUrl={PagesContentArray.privacy.contentArray[i].imgUrl}
              linkUrl={PagesContentArray.privacy.contentArray[i].linkUrl}
            />
          );
        })}
      </div>
    </div>
  );
};
export default PagePrivacy;
