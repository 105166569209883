import React, { useState } from "react";
import "./Page.css";
import PageRow from "./PageRow";
import { PagesContentArray } from "./content/PagesContentArray";
import { getDatabase, ref, update } from "firebase/database";
import { app } from "../firebase";
const PageSupport = () => {
  const [isMobile, setIsMobile] = useState(0);
  const [submitting, setSubmitting] = useState(0);

  function updateIsMobile() {
    if (isMobile) {
      if (window.innerWidth > 808) {
        setIsMobile(0);
      }
    } else {
      if (window.innerWidth < 808) {
        setIsMobile(1);
      }
    }
  }

  updateIsMobile();
  window.addEventListener("resize", updateIsMobile);

  function gatherMessage() {
    if (!app) {
      return null;
    }
    var formInputs = document.getElementsByTagName("input");
    var formTextAreas = document.getElementsByTagName("textarea");
    var formInputsArr = Array.from(formInputs);
    var formTextAreasArr = Array.from(formTextAreas);
    var formArr = formInputsArr.concat(formTextAreasArr);
    var submittedForm = {};
    formArr.map((i) => (submittedForm[i.id] = i.value));
    return submittedForm;
  }

  function sendToFirebase(submittedForm) {
    const db = getDatabase();
    var dbLoc = ref(db, PagesContentArray.support.id);
    var currentdate = new Date();
    var entryId =
      ("0" + currentdate.getFullYear()).slice(-4) +
      ("0" + (currentdate.getMonth() + 1)).slice(-2) +
      ("0" + currentdate.getDate()).slice(-2) +
      ("0" + currentdate.getHours()).slice(-2) +
      ("0" + currentdate.getMinutes()).slice(-2) +
      ("0" + currentdate.getSeconds()).slice(-2) +
      // eslint-disable-next-line
      parseInt(performance.now()).toString().slice(-3);
    var messagePacket = { [entryId]: submittedForm };
    update(dbLoc, messagePacket).then(null);
  }

  function submitContactForm(event) {
    event.preventDefault();
    sendToFirebase(gatherMessage());
    setSubmitting(1);
    setTimeout(() => {
      setSubmitting(0);
    }, 2620);
    setTimeout(() => {
      document.getElementById("dillForm").reset();
    }, 1618);
  }
  return (
    <div className="Page">
      <div className="PageTitle">{PagesContentArray.support.title}</div>
      <div className="PageContentColumn">
        <PageRow
          key={PagesContentArray.support.contentArray[0].id}
          id={PagesContentArray.support.contentArray[0].id}
          parentAppPage={PagesContentArray.support.appPage}
          hidden={PagesContentArray.support.contentArray[0].hidden}
          columnNo={PagesContentArray.support.contentArray[0].columnNo}
          form={PagesContentArray.support.contentArray[0].form}
          button={PagesContentArray.support.contentArray[0].button}
          title={PagesContentArray.support.contentArray[0].title}
          link={PagesContentArray.support.contentArray[0].link}
          img={PagesContentArray.support.contentArray[0].img}
          text={PagesContentArray.support.contentArray[0].text}
          option={PagesContentArray.support.contentArray[0].option}
          imgUrl={PagesContentArray.support.contentArray[0].imgUrl}
          linkUrl={PagesContentArray.support.contentArray[0].linkUrl}
        />
      </div>
      <form
        id="dillForm"
        onSubmit={(event) => submitContactForm(event)}
        className="PageContentColumn"
      >
        {(() => {
          if (isMobile) {
            return (
              <input
                id="Name"
                className="InputBox SpecialInputBox"
                type="text"
                placeholder="Your full name"
                required=""
                name="Name"
              />
            );
          } else {
            return (
              <label className="GenericInput">
                <div className="GenericInputLabel">Name</div>
                <input
                  id="Name"
                  className="InputBox GenericInputBox"
                  type="text"
                  placeholder="Your full name"
                  required=""
                  name="Name"
                />
              </label>
            );
          }
        })()}
        {(() => {
          if (isMobile) {
            return (
              <input
                id="Tel"
                className="InputBox SpecialInputBox"
                type="text"
                placeholder="Your telephone number"
                required=""
                name="tel"
              />
            );
          } else {
            return (
              <label className="GenericInput">
                <div className="GenericInputLabel">tel</div>
                <input
                  id="Tel"
                  className="InputBox GenericInputBox"
                  type="text"
                  placeholder="Your telephone number"
                  required=""
                  name="tel"
                />
              </label>
            );
          }
        })()}
        {(() => {
          if (isMobile) {
            return (
              <input
                id="Email"
                className="InputBox SpecialInputBox"
                type="text"
                placeholder="Your email address"
                required=""
                name="email"
              />
            );
          } else {
            return (
              <label className="GenericInput">
                <div className="GenericInputLabel">email</div>
                <input
                  id="Email"
                  className="InputBox GenericInputBox"
                  type="text"
                  placeholder="Your email address"
                  required=""
                  name="email"
                />
              </label>
            );
          }
        })()}

        {PagesContentArray.support.contentArray.slice(1).map((id, i) => {
          return (
            <PageRow
              key={PagesContentArray.support.contentArray.slice(1)[i].id + i}
              id={PagesContentArray.support.contentArray.slice(1)[i].id}
              parentAppPage={PagesContentArray.support.appPage}
              hidden={PagesContentArray.support.contentArray.slice(1)[i].hidden}
              columnNo={
                PagesContentArray.support.contentArray.slice(1)[i].columnNo
              }
              form={PagesContentArray.support.contentArray.slice(1)[i].form}
              button={PagesContentArray.support.contentArray.slice(1)[i].button}
              title={PagesContentArray.support.contentArray.slice(1)[i].title}
              link={PagesContentArray.support.contentArray.slice(1)[i].link}
              img={PagesContentArray.support.contentArray.slice(1)[i].img}
              text={PagesContentArray.support.contentArray.slice(1)[i].text}
              option={PagesContentArray.support.contentArray.slice(1)[i].option}
              imgUrl={PagesContentArray.support.contentArray.slice(1)[i].imgUrl}
              linkUrl={
                PagesContentArray.support.contentArray.slice(1)[i].linkUrl
              }
            />
          );
        })}
        {(() => {
          if (!submitting) {
            return (
              <button id="FormSend" type="submit" className="FormButton">
                Send to dill
              </button>
            );
          } else {
            return (
              <button
                id="FormSend"
                type="submit"
                className="FormButton FormButtonSubmitting"
              >
                Sent to dill!
              </button>
            );
          }
        })()}
      </form>
    </div>
  );
};
export default PageSupport;
