import React, { useEffect, useState } from "react";
import Logo1 from "../../assets/images/Logo.svg";
import Logo2 from "../../assets/images/Logo-2.svg";
import "./Navigation.css";

const Navigation2 = (props) => {
  const [state, setState] = useState({
    displayNav: false,
    style: "main-navm hidden",
    icon: "menu",
  });

  const menuClickHandler = () => {
    if (state.displayNav === false) {
      setState({
        displayNav: true,
        style: "main-navm show",
        icon: "close",
      });
    } else {
      setState({
        displayNav: false,
        style: "main-navm hidden",
        icon: "menu",
      });
    }
  };

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      setTimeout(() => {}, 20);
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <div className="nav-container" style={{ background: "#C9D8C1" }}>
      {windowSize.innerWidth <= 500 ? (
        <nav className={"notSticky"}>
          <div className="logo-container">
            <a href="/#">
              <img className="logo" src={Logo2} alt="dil Logo" />
              <img
                className="logo-black"
                src={Logo1}
                alt="dill Logo"
                style={{ marginLeft: 30 }}
              />
            </a>
          </div>
          <ul className={"main-nav show"}>
            <li>
              <div className="navigation-ScheduleAppointment2">
                <a
                  href="/BookVisit"
                  style={{
                    textTransform: "none",
                  }}
                >
                  Schedule Appointment
                </a>
              </div>
            </li>
          </ul>

          <ul className={state.style}>
            <li>
              <a className="mobile-nav-icon2" onClick={menuClickHandler}>
                <ion-icon
                  name={state.icon}
                  style={{ color: "black" }}
                ></ion-icon>
              </a>
            </li>

            <li>
              <a
                style={{ textTransform: "none", marginRight: "10vh" }}
                href="/#aboutUs"
              >
                About us
              </a>
            </li>
            <li>
              <a
                style={{
                  textTransform: "none",
                  marginRight: "10vh",
                }}
                href="/Support"
              >
                Support
              </a>
            </li>
            <li>
              <a
                style={{ textTransform: "none", marginRight: "10vh" }}
                href="/#joining"
              >
                Joining
              </a>
            </li>
            <li>
              <a
                style={{ textTransform: "none", marginRight: "10vh" }}
                href="/#pricing"
              >
                Pricing
              </a>
            </li>
            <li>
              <div className="navigation-ScheduleAppointment">
                <a
                  className="navigation-ScheduleAppointment"
                  href="/BookVisit"
                  style={{
                    textTransform: "none",
                  }}
                >
                  Schedule Appointment
                </a>
              </div>
            </li>
          </ul>

          <a className="mobile-nav-icon" onClick={menuClickHandler}>
            <ion-icon name={state.icon} style={{ color: "black" }}></ion-icon>
          </a>
        </nav>
      ) : (
        <nav className={"notSticky"}>
          <div className="logo-container">
            <a href="/#">
              <img className="logo" src={Logo2} alt="dil Logo" />
              <img
                className="logo-black"
                src={Logo2}
                alt="dill Logo"
                style={{ marginLeft: 30 }}
              />
            </a>
          </div>
          <ul
            className="main-nav hidden"
            style={{ marginRight: "calc(var(--dwp2) * 60)" }}
          >
            <li>
              <a
                style={{
                  textTransform: "none",
                  marginRight: "calc(var(--dwp2) * 60)",
                }}
                href="/#aboutUs"
              >
                About us
              </a>
            </li>
            {windowSize.innerWidth <= 600 ? null : (
              <li>
                <a
                  style={{
                    textTransform: "none",
                    marginRight: "calc(var(--dwp2) * 60)",
                  }}
                  href="/Support"
                >
                  Support
                </a>
              </li>
            )}
            {windowSize.innerWidth <= 700 ? null : (
              <li>
                <a
                  style={{
                    textTransform: "none",
                    marginRight: "calc(var(--dwp2) * 60)",
                  }}
                  href="/#joining"
                >
                  Joining
                </a>
              </li>
            )}
            {windowSize.innerWidth <= 800 ? null : (
              <li>
                <a
                  style={{
                    textTransform: "none",
                    marginRight: "calc(var(--dwp2) * 60)",
                  }}
                  href="/#pricing"
                >
                  Pricing
                </a>
              </li>
            )}
            <li>
              <div className="navigation-ScheduleAppointment">
                <a
                  href="/BookVisit"
                  style={{
                    textTransform: "none",
                  }}
                >
                  Schedule Appointment
                </a>
              </div>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
};

export default Navigation2;
