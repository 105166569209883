import React from "react";
import "./Page.css";
import PageRow from "./PageRow";
import { PagesContentArray } from "./content/PagesContentArray";

const Page = () => {
  return (
    <div className="Page">
      <div className="PageTitle">{PagesContentArray.privacyPolicy.title}</div>
      <div className="PageContentColumn">
        {PagesContentArray.privacyPolicy.contentArray.map((id, i) => {
          return (
            <PageRow
              key={PagesContentArray.privacyPolicy.contentArray[i].id + i}
              id={PagesContentArray.privacyPolicy.contentArray[i].id}
              parentAppPage={PagesContentArray.privacyPolicy.appPage}
              hidden={PagesContentArray.privacyPolicy.contentArray[i].hidden}
              columnNo={
                PagesContentArray.privacyPolicy.contentArray[i].columnNo
              }
              form={PagesContentArray.privacyPolicy.contentArray[i].form}
              button={PagesContentArray.privacyPolicy.contentArray[i].button}
              title={PagesContentArray.privacyPolicy.contentArray[i].title}
              link={PagesContentArray.privacyPolicy.contentArray[i].link}
              img={PagesContentArray.privacyPolicy.contentArray[i].img}
              text={PagesContentArray.privacyPolicy.contentArray[i].text}
              option={PagesContentArray.privacyPolicy.contentArray[i].option}
              imgUrl={PagesContentArray.privacyPolicy.contentArray[i].imgUrl}
              linkUrl={PagesContentArray.privacyPolicy.contentArray[i].linkUrl}
            />
          );
        })}
      </div>
    </div>
  );
};
export default Page;
