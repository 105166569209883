import React from "react";
import "./Page.css";

import PageRow from "./PageRow";
import { PagesContentArray } from "./content/PagesContentArray";

const PageEnvironment = () => {
  return (
    <div className="Page">
      <div className="PageTitle">{PagesContentArray.environment.title}</div>
      <div className="PageContentColumn">
        {PagesContentArray.environment.contentArray.map((id, i) => {
          return (
            <PageRow
              key={PagesContentArray.environment.contentArray[i].id + i}
              id={PagesContentArray.environment.contentArray[i].id}
              parentAppPage={PagesContentArray.environment.appPage}
              hidden={PagesContentArray.environment.contentArray[i].hidden}
              columnNo={PagesContentArray.environment.contentArray[i].columnNo}
              form={PagesContentArray.environment.contentArray[i].form}
              button={PagesContentArray.environment.contentArray[i].button}
              title={PagesContentArray.environment.contentArray[i].title}
              link={PagesContentArray.environment.contentArray[i].link}
              img={PagesContentArray.environment.contentArray[i].img}
              text={PagesContentArray.environment.contentArray[i].text}
              option={PagesContentArray.environment.contentArray[i].option}
              imgUrl={PagesContentArray.environment.contentArray[i].imgUrl}
              linkUrl={PagesContentArray.environment.contentArray[i].linkUrl}
            />
          );
        })}
      </div>
    </div>
  );
};
export default PageEnvironment;
