import React from "react";

import Navigation2 from "../components/Navigation/Navigation2";
import PageInclusionAndDiversity from "../components/PageInclusionAndDiversity";
const InclusionAndDiversity = () => {
  return (
    <div>
      <Navigation2 />
      <PageInclusionAndDiversity />
    </div>
  );
};

export default InclusionAndDiversity;
