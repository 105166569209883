import React from "react";
import "./Sec5Trans6.css";
import sec561 from "../../assets/images/sec5to6-1.png";
import sec562 from "../../assets/images/sec5to6-2.png";
import sec563 from "../../assets/images/sec5to6-3.png";
import sec564 from "../../assets/images/sec5to6-4.png";
import sec565 from "../../assets/images/sec5to6-5.png";
import sec566 from "../../assets/images/sec5to6-6.png";
import sec567 from "../../assets/images/sec5to6-7.png";
import sec568 from "../../assets/images/sec5to6-8.png";
import sec569 from "../../assets/images/sec5to6-9.png";

const Sec5Trans6 = (props) => {
  return (
    <div id={""}>
      <div className="section5to6-container">
        <img src={sec561} className="section5to6-img1" alt="sec56-1" />
        <img src={sec562} className="section5to6-img2" alt="sec56-2" />
        <img src={sec563} className="section5to6-img3" alt="sec56-3" />
        <img src={sec564} className="section5to6-img4" alt="sec56-4" />
        <img src={sec565} className="section5to6-img5" alt="sec56-5" />
        <img src={sec566} className="section5to6-img6" alt="sec56-6" />
        <img src={sec567} className="section5to6-img7" alt="sec56-7" />
        <img src={sec568} className="section5to6-img8" alt="sec56-8" />
        <img src={sec569} className="section5to6-img9" alt="sec56-9" />
      </div>
    </div>
  );
};

export default Sec5Trans6;
