import React from "react";
import "./Sec7.css";
import tick from "../../assets/images/tableTick.svg";
import dilLogo from "../../assets/images/dillLogo(3).svg";

const Sec7Table = (props) => {
  return (
    <div id={""}>
      <div>
        <div className="sec7-logo-container">
          <img src={dilLogo} className="section7-dill-logo" alt="sec7-1" />
        </div>
        <div className="section7-table-container">
          <div className="section7-table-part1">
            <div className="section7-table-part1-up">
              <div className="table-part1"></div>
              <div className="table-part2">
                <a className="packet-name">dill</a>
                <a className="packet-price">Standard</a>
                <a className="packet-price">$299</a>
                <a className="packet-duration"> per month</a>
                <div className="packet-button">
                  <a href="/BookVisit">get Standard</a>
                </div>
              </div>
              <div className="table-part2-22">
                <a className="packet-name">dill</a>
                <a className="packet-price">Pro</a>
                <a className="packet-price">$599</a>
                <a className="packet-duration"> per month</a>
                <div className="packet-button">
                  <a href="/BookVisit">get Pro</a>
                </div>
              </div>
              <div className="table-part2-3">
                <a className="packet-name">dill</a>
                <a className="packet-price">Enterprise</a>
                <a className="packet-price">$1199</a>
                <a className="packet-duration"> per month</a>
                <div className="packet-button">
                  <a href="/BookVisit">Contact</a>
                </div>
              </div>
              <div className="table-part2-4">
                <a className="packet-name">dill</a>
                <a className="packet-price">NYC Offer</a>
                <a className="packet-price">FREE</a>
                <a className="packet-duration"> FOR LIFE</a>
                <div className="packet-button2">
                  <a href="/BookVisit">Contact</a>
                </div>
              </div>
            </div>
          </div>
          <div className="section7-table-part2">
            <div className="part2-table-container">
              <div className="part2-table">
                <div className="part2-table-left-first">
                  <h4>Payment Processing</h4>
                </div>
                <div className="part2-table-right">
                  <p>1.50%</p>
                </div>
                <div className="part2-table-right2">
                  <p>1.50%</p>
                </div>
                <div className="part2-table-right3">
                  <p>1.50%</p>
                </div>
                <div className="part2-table-right4">
                  <p>1.50%</p>
                </div>
              </div>
              <div className="part2-table">
                <div className="part2-table-left">
                  <h5>Tools that attract more customers for you:</h5>
                </div>
                <div className="part2-table-right"></div>
                <div className="part2-table-right2"></div>
                <div className="part2-table-right3"></div>
                <div className="part2-table-right4"></div>
              </div>
              <div className="part2-table">
                <div className="part2-table-left">
                  <ul>
                    <li>List your restaurant on our platform</li>
                  </ul>
                </div>
                <div className="part2-table-right">
                  <img src={tick} alt="sec7-2" />
                </div>
                <div className="part2-table-right2">
                  <img src={tick} alt="sec7-3" />
                </div>
                <div className="part2-table-right3">
                  <img src={tick} alt="sec7-4" />
                </div>
                <div className="part2-table-right4">
                  <img src={tick} alt="sec7-5" />
                </div>
              </div>
              <div className="part2-table">
                <div className="part2-table-left">
                  <ul>
                    <li>Send targeted ads to guests</li>
                  </ul>
                </div>
                <div className="part2-table-right"></div>
                <div className="part2-table-right2">
                  <img src={tick} alt="sec7-7" />
                </div>
                <div className="part2-table-right3">
                  <img src={tick} alt="sec7-8" />
                </div>
                <div className="part2-table-right4">
                  <img src={tick} alt="sec7-9" />
                </div>
              </div>
              <div className="part2-table">
                <div className="part2-table-left">
                  <ul>
                    <li>
                      Inform guests of limited-time offers (dynamic pricing)
                    </li>
                  </ul>
                </div>
                <div className="part2-table-right"></div>
                <div className="part2-table-right2">
                  <img src={tick} alt="sec7-10" />
                </div>
                <div className="part2-table-right3">
                  <img src={tick} alt="sec7-11" />
                </div>
                <div className="part2-table-right4">
                  <img src={tick} alt="sec7-12" />
                </div>
              </div>
              <div className="part2-table">
                <div className="part2-table-left">
                  <ul>
                    <li>
                      {" "}
                      Send special offers for regulars or those who had a fluke
                      bad experience
                    </li>
                  </ul>
                </div>
                <div className="part2-table-right"></div>
                <div className="part2-table-right2">
                  <img src={tick} alt="sec7-13" />
                </div>
                <div className="part2-table-right3">
                  <img src={tick} alt="sec7-14" />
                </div>
                <div className="part2-table-right4">
                  <img src={tick} alt="sec7-15" />
                </div>
              </div>
              <div className="part2-table">
                <div className="part2-table-left">
                  <ul>
                    <li>
                      Get guests recommending your restaurant to each other
                    </li>
                  </ul>
                </div>
                <div className="part2-table-right">
                  <img src={tick} alt="sec7-16" />
                </div>
                <div className="part2-table-right2">
                  <img src={tick} alt="sec7-17" />
                </div>
                <div className="part2-table-right3">
                  <img src={tick} alt="sec7-18" />
                </div>
                <div className="part2-table-right4">
                  <img src={tick} alt="sec7-19" />
                </div>
              </div>
              <div className="part2-table">
                <div className="part2-table-left">
                  <ul>
                    <li>Use our intelligent reservation platform</li>
                  </ul>
                </div>
                <div className="part2-table-right"></div>
                <div className="part2-table-right2">
                  <img src={tick} alt="sec7-20" />
                </div>
                <div className="part2-table-right3">
                  <img src={tick} alt="sec7-21" />
                </div>
                <div className="part2-table-right4">
                  <img src={tick} alt="sec7-22" />
                </div>
              </div>
              <div className="part2-table">
                <div className="part2-table-left">
                  <ul>
                    <li>dill’s engagement-based loyalty program</li>
                  </ul>
                </div>
                <div className="part2-table-right"></div>
                <div className="part2-table-right2">
                  <img src={tick} alt="sec7-23" />
                </div>
                <div className="part2-table-right3">
                  <img src={tick} alt="sec7-24" />
                </div>
                <div className="part2-table-right4">
                  <img src={tick} alt="sec7-25" />
                </div>
              </div>
              <div className="part2-table">
                <div className="part2-table-left">
                  <ul>
                    <li>
                      {" "}
                      Reach new customers through dill lunch corporate voucher
                      schemes
                    </li>
                  </ul>
                </div>
                <div className="part2-table-right">
                  <img src={tick} alt="sec7-26" />
                </div>
                <div className="part2-table-right2">
                  <img src={tick} alt="sec7-27" />
                </div>
                <div className="part2-table-right3">
                  <img src={tick} alt="sec7-28" />
                </div>
                <div className="part2-table-right4">
                  <img src={tick} alt="sec7-29" />
                </div>
              </div>
              <div className="part2-table">
                <div className="part2-table-left">
                  <ul>
                    <li>Reach new customers through dill gift cards</li>
                  </ul>
                </div>
                <div className="part2-table-right"></div>
                <div className="part2-table-right2"></div>
                <div className="part2-table-right3">
                  <img src={tick} alt="sec7-30" />
                </div>
                <div className="part2-table-right4">
                  <img src={tick} alt="sec7-31" />
                </div>
              </div>
              <div className="part2-table">
                <div className="part2-table-left">
                  <h5>Tools that make your life easier:</h5>
                </div>
                <div className="part2-table-right"></div>
                <div className="part2-table-right2"></div>
                <div className="part2-table-right3"></div>
                <div className="part2-table-right4"></div>
              </div>
              <div className="part2-table">
                <div className="part2-table-left">
                  <ul>
                    <li>Use our intelligent waitlist platform</li>
                  </ul>
                </div>
                <div className="part2-table-right">
                  <img src={tick} alt="sec7-32" />
                </div>
                <div className="part2-table-right2">
                  <img src={tick} alt="sec7-33" />
                </div>
                <div className="part2-table-right3">
                  <img src={tick} alt="sec7-34" />
                </div>
                <div className="part2-table-right4">
                  <img src={tick} alt="sec7-35" />
                </div>
              </div>
              <div className="part2-table">
                <div className="part2-table-left">
                  <ul>
                    <li>
                      {" "}
                      See all restaurants individually or as one aggregate live
                      in your phone
                    </li>
                  </ul>
                </div>
                <div className="part2-table-right"></div>
                <div className="part2-table-right2">
                  <img src={tick} alt="sec7-36" />
                </div>
                <div className="part2-table-right3">
                  <img src={tick} alt="sec7-37" />
                </div>
                <div className="part2-table-right4">
                  <img src={tick} alt="sec7-38" />
                </div>
              </div>
              <div className="part2-table">
                <div className="part2-table-left">
                  <ul>
                    <li>
                      Get help organizing, managing, and retaining your servers
                      (FoH)
                    </li>
                  </ul>
                </div>
                <div className="part2-table-right">
                  <img src={tick} alt="sec7-39" />
                </div>
                <div className="part2-table-right2">
                  <img src={tick} alt="sec7-40" />
                </div>
                <div className="part2-table-right3">
                  <img src={tick} alt="sec7-41" />
                </div>
                <div className="part2-table-right4">
                  <img src={tick} alt="sec7-42" />
                </div>
              </div>
              <div className="part2-table">
                <div className="part2-table-left-inner">
                  <ul>
                    <li>Clock-in / clock-out</li>
                  </ul>
                </div>
                <div className="part2-table-right"></div>
                <div className="part2-table-right2"></div>
                <div className="part2-table-right3">
                  <img src={tick} alt="sec7-43" />
                </div>
                <div className="part2-table-right4">
                  <img src={tick} alt="sec7-44" />
                </div>
              </div>
              <div className="part2-table">
                <div className="part2-table-left-inner">
                  <ul>
                    <li>Shift scheduling</li>
                  </ul>
                </div>

                <div className="part2-table-right"></div>
                <div className="part2-table-right2">
                  <img src={tick} alt="sec7-45" />
                </div>
                <div className="part2-table-right3">
                  <img src={tick} alt="sec7-46" />
                </div>
                <div className="part2-table-right4">
                  <img src={tick} alt="sec7-47" />
                </div>
              </div>
              <div className="part2-table">
                <div className="part2-table-left-inner">
                  <ul>
                    <li>Employee performance monitoring</li>
                  </ul>
                </div>

                <div className="part2-table-right"></div>
                <div className="part2-table-right2"></div>
                <div className="part2-table-right3">
                  <img src={tick} alt="sec7-48" />
                </div>
                <div className="part2-table-right4">
                  <img src={tick} alt="sec7-49" />
                </div>
              </div>
              <div className="part2-table">
                <div className="part2-table-left-inner">
                  <ul>
                    <li>Employee improvement tips</li>
                  </ul>
                </div>

                <div className="part2-table-right">
                  <img src={tick} alt="sec7-50" />
                </div>
                <div className="part2-table-right2">
                  <img src={tick} alt="sec7-51" />
                </div>
                <div className="part2-table-right3">
                  <img src={tick} alt="sec7-52" />
                </div>
                <div className="part2-table-right4">
                  <img src={tick} alt="sec7-53" />
                </div>
              </div>
              <div className="part2-table">
                <div className="part2-table-left-inner">
                  <ul>
                    <li>Tip splitting</li>
                  </ul>
                </div>

                <div className="part2-table-right"></div>
                <div className="part2-table-right2"></div>
                <div className="part2-table-right3">
                  <img src={tick} alt="sec7-54" />
                </div>
                <div className="part2-table-right4">
                  <img src={tick} alt="sec7-55" />
                </div>
              </div>
              <div className="part2-table">
                <div className="part2-table-left">
                  <ul>
                    <li>
                      Get help keeping your restaurant staffed when you need it
                    </li>
                  </ul>
                </div>
                <div className="part2-table-right">
                  <img src={tick} alt="sec7-56" />
                </div>
                <div className="part2-table-right2"></div>
                <div className="part2-table-right3">
                  <img src={tick} alt="sec7-57" />
                </div>
                <div className="part2-table-right4">
                  <img src={tick} alt="sec7-58" />
                </div>
              </div>
              <div className="part2-table">
                <div className="part2-table-left-inner">
                  <ul>
                    <li>Meet new servers</li>
                  </ul>
                </div>
                <div className="part2-table-right"></div>
                <div className="part2-table-right2"></div>
                <div className="part2-table-right3">
                  <img src={tick} alt="" />
                </div>
                <div className="part2-table-right4">
                  <img src={tick} alt="sec7-59" />
                </div>
              </div>
              <div className="part2-table">
                <div className="part2-table-left-inner">
                  <ul>
                    <li>
                      {" "}
                      Get temporary outside help on days that you can’t get
                      enough staff
                    </li>
                  </ul>
                </div>

                <div className="part2-table-right">
                  <img src={tick} alt="sec7-60" />
                </div>
                <div className="part2-table-right2"></div>
                <div className="part2-table-right3">
                  <img src={tick} alt="sec7-61" />
                </div>
                <div className="part2-table-right4">
                  <img src={tick} alt="sec7-62" />
                </div>
              </div>
              <div className="part2-table">
                <div className="part2-table-left">
                  <h5>Tools that help inform your decisions:</h5>
                </div>
                <div className="part2-table-right"></div>
                <div className="part2-table-right2"></div>
                <div className="part2-table-right3"></div>
                <div className="part2-table-right4"></div>
              </div>
              <div className="part2-table">
                <div className="part2-table-left">
                  <ul>
                    <li>
                      Guest feedback analyzed and distilled into actionable
                      insights
                    </li>
                  </ul>
                </div>
                <div className="part2-table-right">
                  <img src={tick} alt="sec7-63" />
                </div>
                <div className="part2-table-right2">
                  <img src={tick} alt="sec7-64" />
                </div>
                <div className="part2-table-right3">
                  <img src={tick} alt="sec7-65" />
                </div>
                <div className="part2-table-right4">
                  <img src={tick} alt="sec7-66" />
                </div>
              </div>
              <div className="part2-table">
                <div className="part2-table-left">
                  <ul>
                    <li>
                      AI-backed automation (that you can override) based on data
                      collected at each stage of the process
                    </li>
                  </ul>
                </div>
                <div className="part2-table-right">
                  <img src={tick} alt="sec7-67" />
                </div>
                <div className="part2-table-right2">
                  <img src={tick} alt="sec7-68" />
                </div>
                <div className="part2-table-right3">
                  <img src={tick} alt="sec7-69" />
                </div>
                <div className="part2-table-right4">
                  <img src={tick} alt="sec7-70" />
                </div>
              </div>
              <div className="part2-table">
                <div className="part2-table-left">
                  <ul>
                    <li>
                      AI-backed restaurant improvement tips based on data
                      collected at each stage of the process
                    </li>
                  </ul>
                </div>
                <div className="part2-table-right"></div>
                <div className="part2-table-right2">
                  <img src={tick} alt="sec7-71" />
                </div>
                <div className="part2-table-right3">
                  <img src={tick} alt="sec7-72" />
                </div>
                <div className="part2-table-right4">
                  <img src={tick} alt="sec7-73" />
                </div>
              </div>
              <div className="part2-table">
                <div className="part2-table-left-last">
                  <ul>
                    <li>
                      {" "}
                      AI-backed restaurant cost-cutting tips based on data
                      collected at each stage of the process
                    </li>
                  </ul>
                </div>
                <div className="part2-table-right-last"></div>
                <div className="part2-table-right2-last"></div>
                <div className="part2-table-right3-last">
                  <img src={tick} alt="sec7-74" />
                </div>
                <div className="part2-table-right4-last">
                  <img src={tick} alt="sec7-75" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sec7Table;
