import React, { Component } from "react";
import Logo1 from "../../assets/images/Logo.svg";
import Logo2 from "../../assets/images/Logo-2.svg";
import "./Navigation.css";
class Navigation extends Component {
  state = {
    displayNav: false,
    style: "main-nav hidden",
    icon: "menu",
  };

  menuClickHandler = () => {
    if (this.state.displayNav === false) {
      this.setState({
        displayNav: true,
        style: "main-nav show",
        icon: "close",
      });
    } else {
      this.setState({
        displayNav: false,
        style: "main-nav hidden",
        icon: "menu",
      });
    }
  };

  render() {
    return (
      <div className="nav-container">
        <nav className={"notSticky"}>
          <div className="logo-container">
            <a href="/#">
              <img className="logo" src={Logo2} alt="dil Logo" />
              <img
                className="logo-black"
                src={Logo1}
                alt="dill Logo"
                style={{ marginLeft: 30 }}
              />
            </a>
          </div>
          <ul
            className={this.state.style}
            style={{ marginRight: "calc(var(--dwp2) * 60)" }}
          >
            <li>
              <a
                style={{
                  textTransform: "none",
                  marginRight: "calc(var(--dwp2) * 60)",
                }}
                href="/#aboutUs"
              >
                About us
              </a>
            </li>
            <li>
              <a
                style={{
                  textTransform: "none",
                  marginRight: "calc(var(--dwp2) * 60)",
                }}
                href="/Support"
              >
                Support
              </a>
            </li>
            <li>
              <a
                style={{
                  textTransform: "none",
                  marginRight: "calc(var(--dwp2) * 60)",
                }}
                href="/#joining"
              >
                Joining
              </a>
            </li>
            <li>
              <a
                style={{
                  textTransform: "none",
                  marginRight: "calc(var(--dwp2) * 60)",
                }}
                href="/#pricing"
              >
                Pricing
              </a>
            </li>
            <li>
              <div className="navigation-ScheduleAppointment">
                <a
                  href="/BookVisit"
                  style={{
                    textTransform: "none",
                  }}
                >
                  Schedule Appointment
                </a>
              </div>
            </li>{" "}
          </ul>
          <a className="mobile-nav-icon" onClick={this.menuClickHandler}>
            <ion-icon name={this.state.icon}></ion-icon>
          </a>
        </nav>
      </div>
    );
  }
}

export default Navigation;
