import React, { useEffect, useState } from "react";
import "./Sec7.css";
import Sec7Table from "./Sec7Table";
import pic1 from "../../assets/images/newsec7.png";
import pic2 from "../../assets/images/Sec7.2Text.png";
import pic3 from "../../assets/images/NYCoffer.png";
import dilLogo from "../../assets/images/dillLogo(3).svg";
import pic4 from "../../assets/images/Sec7.2Text(2).svg";
import pic5 from "../../assets/images/NYCoffer(1).svg";
import tick from "../../assets/images/mobiletick.png";

const Sec7 = (props) => {
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      setTimeout(() => {}, 20);
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <div id={"pricing"}>
      {windowSize.innerWidth <= 500 ? (
        <div className="section7-container-m">
          <div className="section7-title-m">
            <h5>So what does it cost ? </h5>
          </div>
          <div className="section7-box1-m-container">
            <div className="section7-box1-m">
              <div className="section7-box1-m-inner">
                <a>A payment processing rate beyond imagination: 1.50%</a>
                <ul>
                  <li>That’s it, no flat fees, no monthly fees</li>
                  <li>
                    Let’s start putting money back into your pocket today.
                  </li>
                </ul>
                <div className="section7-box1-m-inner-dw">
                  <a>No long-term commitment!</a>
                  <ul>
                    <li>
                      Just give us 4-weeks notice and you’ll seamlessly <br />{" "}
                      revert back to your current setup
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="section7-box2-m-container">
            <div className="section7-box2-m">
              <img src={pic5} className="section7-box2-m-pic1" alt="" />
              <img src={pic4} className="section7-box2-m-pic2" alt="" />
              <div className="learn-more-7">
                <a href="/BookVisit">Learn more</a>
              </div>
            </div>
          </div>

          <div className="section7-logo-m-container">
            <img src={dilLogo} className="section7-dill-logo-m" alt="" />
          </div>

          <div className="section7-box3-m-container">
            <div className="section7-box-m">
              <div className="section7-box-m-header">
                <a className="packet-name">dill</a>
                <a className="packet-price">Standard</a>
                <a className="packet-price">$299</a>
                <a className="packet-duration"> per month</a>
                <div className="packet-button">
                  <a href="/BookVisit">get Standard</a>
                </div>
              </div>
              <div className="section7-box-m-body">
                <div className="section7-box-m-body-1">
                  <h4>Payment Processing</h4>
                  <a>1.50%</a>
                </div>
                <div className="section7-box-m-body-2">
                  <h4>Tools that bring in more customers:</h4>
                  <div className="section7-box-m-body-2-child">
                    <ul>
                      <li>List your restaurant on our platform</li>
                    </ul>
                    <div className="tick-container">
                      <img src={tick} alt="" />
                    </div>
                  </div>
                  <div className="section7-box-m-body-2-child">
                    <ul>
                      <li>Send targeted ads to guests</li>
                    </ul>
                    <div className="tick-container">
                      <img src={tick} alt="" />
                    </div>
                  </div>
                  <div className="section7-box-m-body-2-child">
                    <ul>
                      <li>
                        Inform guests of limited-time offers (dynamic pricing)
                      </li>
                    </ul>
                    <div className="tick-container">
                      <img src={tick} alt="" />
                    </div>
                  </div>
                  <div className="section7-box-m-body-2-child">
                    <ul>
                      <li>Use our intelligent reservation platform</li>
                    </ul>
                    <div className="tick-container">
                      <img src={tick} alt="" />
                    </div>
                  </div>
                  <div className="section7-box-m-body-2-child">
                    <ul>
                      <li>dill’s engagement-based loyalty program</li>
                    </ul>
                    <div className="tick-container">
                      <img src={tick} alt="" />
                    </div>
                  </div>
                </div>
                <div className="section7-box-m-body-2">
                  <h4>Tools that make your life easier:</h4>
                  <div className="section7-box-m-body-2-child">
                    <ul>
                      <li>Use our intelligent waitlist platform</li>
                    </ul>
                    <div className="tick-container">
                      <img src={tick} alt="" />
                    </div>
                  </div>
                  <div className="section7-box-m-body-2-child">
                    <ul>
                      <li>
                        Get help organizing, managing, and retaining your
                        servers (FoH):
                      </li>
                    </ul>
                    <div className="tick-container"></div>
                  </div>
                  <div className="section7-box-m-body-2-child-inner">
                    <ul>
                      <li>Clock-in / clock-out</li>
                    </ul>
                    <div className="tick-container">
                      <img src={tick} alt="" />
                    </div>
                  </div>
                  <div className="section7-box-m-body-2-child-inner">
                    <ul>
                      <li>Shift scheduling</li>
                    </ul>
                    <div className="tick-container">
                      <img src={tick} alt="" />
                    </div>
                  </div>
                  <div className="section7-box-m-body-2-child-inner">
                    <ul>
                      <li>Tip splitting</li>
                    </ul>
                    <div className="tick-container">
                      <img src={tick} alt="" />
                    </div>
                  </div>
                  <div className="section7-box-m-body-2-child">
                    <ul>
                      <li>
                        Get help keeping your restaurant staffed when you need
                        it:
                      </li>
                    </ul>
                    <div className="tick-container"></div>
                  </div>
                  <div className="section7-box-m-body-2-child-inner">
                    <ul>
                      <li>
                        Get temporary outside help on days that you can’t get
                        enough staff
                      </li>
                    </ul>
                    <div className="tick-container">
                      <img src={tick} alt="" />
                    </div>
                  </div>
                </div>
                <div className="section7-box-m-body-2">
                  <h4>Tools that help inform your decisions:</h4>
                  <div className="section7-box-m-body-2-child">
                    <ul>
                      <li>
                        Guest feedback analyzed and distilled into actionable
                        insights
                      </li>
                    </ul>
                    <div className="tick-container">
                      <img src={tick} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section7-box4-m-container">
            <div className="section7-box4-m">
              <div className="section7-box-m-header">
                <a className="packet-name">dill</a>
                <a className="packet-price">Pro</a>
                <a className="packet-price">$599</a>
                <a className="packet-duration"> per month</a>
                <div className="packet-button">
                  <a href="/BookVisit">get Pro</a>
                </div>
              </div>
              <div className="section7-box-m-body">
                <div className="section7-box-m-body-1-w">
                  <h4>Everything included in dill Standard plus:</h4>
                  <div className="tick-container">
                    <img src={tick} alt="" />
                  </div>
                </div>
                <div className="section7-box-m-body-2">
                  <h4>Tools that bring in more customers:</h4>
                  <div className="section7-box-m-body-2-child">
                    <ul>
                      <li>
                        Send special offers for regulars or those who had a
                        fluke bad experience
                      </li>
                    </ul>
                    <div className="tick-container">
                      <img src={tick} alt="" />
                    </div>
                  </div>
                  <div className="section7-box-m-body-2-child">
                    <ul>
                      <li>
                        Get guests recommending your restaurant to each other
                      </li>
                    </ul>
                    <div className="tick-container">
                      <img src={tick} alt="" />
                    </div>
                  </div>
                  <div className="section7-box-m-body-2-child">
                    <ul>
                      <li>
                        Reach new customers through dill lunch corporate voucher
                        schemes
                      </li>
                    </ul>
                    <div className="tick-container">
                      <img src={tick} alt="" />
                    </div>
                  </div>
                </div>
                <div className="section7-box-m-body-2">
                  <h4>Tools that make your life easier:</h4>
                  <div className="section7-box-m-body-2-child">
                    <ul>
                      <li>
                        Get help organizing, managing, and retaining your
                        servers (FoH):
                      </li>
                    </ul>
                    <div className="tick-container"></div>
                  </div>

                  <div className="section7-box-m-body-2-child-inner">
                    <ul>
                      <li>Employee performance monitoring</li>
                    </ul>
                    <div className="tick-container">
                      <img src={tick} alt="" />
                    </div>
                  </div>
                  <div className="section7-box-m-body-2-child-inner">
                    <ul>
                      <li>Employee improvement tips</li>
                    </ul>
                    <div className="tick-container">
                      <img src={tick} alt="" />
                    </div>
                  </div>
                  <div className="section7-box-m-body-2-child-inner">
                    <ul>
                      <li>Employee improvement incentivization</li>
                    </ul>
                    <div className="tick-container">
                      <img src={tick} alt="" />
                    </div>
                  </div>

                  <div className="section7-box-m-body-2-child">
                    <ul>
                      <li>
                        Get help keeping your restaurant staffed when you need
                        it:
                      </li>
                    </ul>
                    <div className="tick-container"></div>
                  </div>
                  <div className="section7-box-m-body-2-child-inner">
                    <ul>
                      <li>Meet new servers</li>
                    </ul>
                    <div className="tick-container">
                      <img src={tick} alt="" />
                    </div>
                  </div>
                </div>
                <div className="section7-box-m-body-2">
                  <h4>Tools that help inform your decisions:</h4>
                  <div className="section7-box-m-body-2-child">
                    <ul>
                      <li>
                        AI-backed automation based on data collected at each
                        stage of the process
                      </li>
                    </ul>
                    <div className="tick-container">
                      <img src={tick} alt="" />
                    </div>
                  </div>
                  <div className="section7-box-m-body-2-child">
                    <ul>
                      <li>AI-backed restaurant improvement tips</li>
                    </ul>
                    <div className="tick-container">
                      <img src={tick} alt="" />
                    </div>
                  </div>
                  <div className="section7-box-m-body-2-child">
                    <ul>
                      <li>AI-backed restaurant cost-cutting tips</li>
                    </ul>
                    <div className="tick-container">
                      <img src={tick} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section7-box5-m-container">
            <div className="section7-box5-m">
              <div className="section7-box-m-header">
                <a className="packet-name">dill</a>
                <a className="packet-price">Enterprise</a>
                <a className="packet-price">$1199</a>
                <a className="packet-duration"> per month</a>
                <div className="packet-button">
                  <a href="/BookVisit">Contact</a>
                </div>
              </div>
              <div className="section7-box-m-body">
                <div className="section7-box-m-body-1-w">
                  <h4>Everything included in dill Pro plus:</h4>
                  <div className="tick-container">
                    <img src={tick} alt="" />
                  </div>
                </div>

                <div className="section7-box-m-body-2">
                  <h4>Tools that make your life easier:</h4>
                  <div className="section7-box-m-body-2-child">
                    <ul>
                      <li>Multi-restaurant support:</li>
                    </ul>
                    <div className="tick-container"></div>
                  </div>

                  <div className="section7-box-m-body-2-child-inner">
                    <ul>
                      <li>
                        See all restaurants individually or as one aggregate
                        live in your phone
                      </li>
                    </ul>
                    <div className="tick-container">
                      <img src={tick} alt="" />
                    </div>
                  </div>

                  <div className="section7-box-m-body-2-child">
                    <ul>
                      <li>Price is for entire franchise</li>
                    </ul>
                    <div className="tick-container"> </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section7-box6-m-container">
            <div className="section7-box6-m">
              <div className="section7-box-m-header">
                <a className="packet-name">dill</a>
                <a className="packet-price">NYC Offer</a>
                <a className="packet-price">FREE</a>
                <a className="packet-duration"> FOR LIFE</a>
                <div className="packet-button2">
                  <a href="/BookVisit">Contact</a>
                </div>
              </div>
              <div className="section7-box-m-body">
                <div className="section7-box-m-body-1-w">
                  <h4>Everything included in dill Enterprise plus:</h4>
                  <div className="tick-container">
                    <img src={tick} alt="" />
                  </div>
                </div>{" "}
                <div className="section7-box-m-body-2">
                  <div className="section7-box-m-body-2-child">
                    <a>It’s FREE</a>

                    <div className="tick-container">
                      <img src={tick} alt="" />
                    </div>
                  </div>
                  <div className="section7-box-m-body-2-child">
                    <a>For LIFE</a>

                    <div className="tick-container">
                      <img src={tick} alt="" />
                    </div>
                  </div>
                  <div className="section7-box-m-body-2-child-inner">
                    <ul>
                      <li>For a limited number of restaurants only</li>
                    </ul>
                    <div className="tick-container">
                      <img src={tick} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="section7-container">
          <div className="section7-title">
            <h5>So what does it cost ? </h5>
          </div>
          <div className="section7-box1-container">
            <div className="section7-box1">
              <div className="section7-box1-1">
                <div className="section7-box1-1-up">
                  <a>
                    A payment processing rate beyond <br />
                    imagination: 1.50%
                  </a>
                  <ul>
                    <li>That’s it, no flat fees, no monthly fees</li>
                    <li>
                      Let’s start putting money back into your pocket today.
                    </li>
                  </ul>
                </div>
                <div className="section7-box1-1-down">
                  <a>No long-term commitment!</a>
                  <ul>
                    <li>
                      Just give us 4-weeks notice and you’ll seamlessly revert back to your current setup
                    </li>
                  </ul>
                </div>
              </div>
              <div className="section7-box1-2">
                <img src={pic1} className="section7-img1" alt="" />
              </div>
            </div>
          </div>
          <div className="section7-box2-container">
            <div className="section7-box2">
              <img src={pic3} className="nyc-offer" alt="" />
              <img src={pic2} className="nyc-offer-text" alt="" />
            </div>
          </div>
          <Sec7Table />
        </div>
      )}
    </div>
  );
};

export default Sec7;
