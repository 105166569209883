import React from "react";

import Navigation2 from "../components/Navigation/Navigation2";
import Page from "../components/Page";
const PrivacyPolicy = () => {
  return (
    <div>
      <Navigation2 />
      <Page />
    </div>
  );
};

export default PrivacyPolicy;
