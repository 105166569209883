import React from "react";
import ReactDOM from "react-dom";
import "normalize.css";
import "./assets/css/grid.css";
import "./index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import "./fonts/SkModernist-Bold.otf";
import "./fonts/SkModernist-Regular.otf";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
registerServiceWorker();
