import React from "react";

import Navigation2 from "../components/Navigation/Navigation2";

import PageContact from "../components/PageContact";
const Contact = () => {
  return (
    <div>
      <Navigation2 />
      <PageContact />
    </div>
  );
};

export default Contact;
