import React, { useEffect, useState } from "react";
import "./Sec4.css";
import imageOne from "../../assets/images/sec4-image1.png";
import imageTwo from "../../assets/images/sec4-image2.png";
import imageThree from "../../assets/images/bigImage.png";
import Sec4Trans5 from "../Sec4Trans5/Sec4Trans5";
import one from "../../assets/images/mobil2.png";
import two from "../../assets/images/mobil1.png";

const Sec4 = (props) => {
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      setTimeout(() => {}, 20);
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <div id={""}>
      <div className="section4-container">
        <div
          className={
            windowSize.innerWidth > 1512
              ? "section4-main-container-b"
              : "section4-main-container"
          }
        >
          <div className="section4-gap"></div>
          <div className="section4-part-1-1">
            <h3>About dill</h3>
            <div className="section4-part1-row">
              <div className="section4-part-1-1-main-description">
                <div className="section4-part-1-1-description-1">
                  <p>
                    Our mission is to promote eating out, as we see social
                    eating as a quintessential human experience.
                  </p>
                  <p>
                    We are focused on streamlining restaurant ownership
                    further into profitability while reducing the ecological
                    impact of feeding humans.
                  </p>
                  <ul>
                    <li style={{ listStyle: "inside" }}>
                      The dill team has been working hard in stealth since 2020
                      to bring in the next big leap in restaurant tech, and
                      promote dining out to an even stronger position than it
                      was before the pandemic
                    </li>
                    <li style={{ listStyle: "inside" }}>
                      We’ve built a multi-sided platform that connects all
                      parties involved in the dining experience which enables us
                      to identify and fix issues where they occur, when they
                      occur
                    </li>
                    <li style={{ listStyle: "inside" }}>
                      In order to not disrupt the flow of your restaurant we’ve
                      developed a system that is compatible with your current
                      setup which enables you to smoothly and seamlessly
                      transition to dill, reducing your technology costs with
                      every feature
                    </li>
                  </ul>
                </div>
              </div>
              <div className="section4-image1-main">
                <img
                  alt=""
                  src={windowSize.innerWidth > 1512 ? imageThree : imageOne}
                  className={
                    windowSize.innerWidth > 1512
                      ? "section4-image1-b"
                      : "section4-image1"
                  }
                />
              </div>
            </div>
          </div>
          <div className="section4-part-2-2">
            {windowSize.innerWidth <= 500 ? (
              <div className="section4-part2-row">
                <div className="section4-part-2-2-main-description">
                  <div className="section4-part-2-2-description-1">
                    <ul>
                      <li style={{ listStyle: "inside" }}>
                        dill’s founder worked in restaurants from the age of 15
                        to the end of his masters degree, as a line cook, a
                        server, and with experience in managing and catering.
                        After building a career in fortune 100 companies, from
                        components to systems and system thinking he focused his
                        skills on renewable energy and the environment in the
                        Silicon Valley tech scene. After becoming a veteran in
                        startups of all stages, 2nd time founder, Stephen
                        Radmard, is applying his depth and breadth of knowledge
                        on streamlining an industry held close-to-heart,
                        restaurants.
                      </li>
                    </ul>
                    <ul>
                      <li style={{ listStyle: "inside" }}>
                        We sympathize with the sensitivity of a restaurant’s
                        bottom-line. We believe that the current restaurant tech
                        market does not offer good value-for-money to
                        restaurants. We are here to change that.
                      </li>
                      <li style={{ listStyle: "inside" }}>
                        With another recession looming, we’re here and we’ve got
                        your back.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
              <div className="section4-part2-row">
                <div className="section4-image2-main">
                  <img
                    alt=""
                    src={imageTwo}
                    className={
                      windowSize.innerWidth > 1512
                        ? "section4-image2-b"
                        : "section4-image2"
                    }
                  />
                </div>
                <div className="section4-part-2-2-main-description">
                  <div className="section4-part-2-2-description-1">
                    <ul>
                      <li style={{ listStyle: "inside" }}>
                        dill’s founder worked in restaurants from the age of 15
                        to the end of his masters degree, as a line cook, a
                        server, and with experience in managing and catering.
                        After building a career in fortune 100 companies, from
                        components to systems and system thinking he focused his
                        skills on renewable energy and the environment in the
                        Silicon Valley tech scene. After becoming a veteran in
                        startups of all stages, 2nd time founder, Stephen
                        Radmard, is applying his depth and breadth of knowledge
                        on streamlining an industry held close-to-heart,
                        restaurants.
                      </li>
                    </ul>
                    <ul>
                      <li style={{ listStyle: "inside" }}>
                        We sympathize with the sensitivity of a restaurant’s
                        bottom-line. We believe that the current restaurant tech
                        market does not offer good value-for-money to
                        restaurants. We are here to change that.
                      </li>
                      <li style={{ listStyle: "inside" }}>
                        With another recession looming, we’re here and we’ve got
                        your back.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {windowSize.innerWidth <= 500 ? (
          <div className="section45t">
            <img src={one} className="section45t1" alt="" />
            <img src={two} className="section45t2" alt="" />
          </div>
        ) : (
          <div className="section4to5-container">
            <div className="section4to5">
              <Sec4Trans5 />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sec4;
