import React from "react";
import "./Sec3Trans4.css";
import mck from "../../assets/images/testMockup.svg";
import mck2 from "../../assets/images/mockup2.svg";
import mck3 from "../../assets/images/mockup3.svg";
import mck4 from "../../assets/images/mockup4.svg";
import mck5 from "../../assets/images/mockup5.svg";
import mck6 from "../../assets/images/mockup6.svg";

const Sec3Trans4 = (props) => {
  return (
    <div id={""}>
      <div className="section3t4-container">
        <img src={mck} className="mockup1 mockupAnimation" alt="" />
        <img src={mck2} className="mockup2 mockupAnimation" alt="" />
        <img src={mck3} className="mockup3 mockupAnimation" alt="" />
        <img src={mck4} className="mockup4 mockupAnimation" alt="" />
        <img src={mck5} className="mockup5 mockupAnimation" alt="" />
        <img src={mck6} className="mockup6 mockupAnimation" alt="" />
        <img src={mck6} className="mockup7 mockupAnimation" alt="" />
        <img src={mck4} className="mockup8 mockupAnimation" alt="" />
        <img src={mck3} className="mockup9 mockupAnimation" alt="" />
        <img src={mck3} className="mockup10 mockupAnimation" alt="" />
        <img src={mck} className="mockup11 mockupAnimation" alt="" />
        <img src={mck2} className="mockup12 mockupAnimation" alt="" />
        <img src={mck3} className="mockup13 mockupAnimation" alt="" />
        <img src={mck4} className="mockup14 mockupAnimation" alt="" />
        <img src={mck5} className="mockup15 mockupAnimation" alt="" />
        <img src={mck6} className="mockup16 mockupAnimation" alt="" />
        <img src={mck5} className="mockup17 mockupAnimation" alt="" />
        <img src={mck4} className="mockup18 mockupAnimation" alt="" />
        <img src={mck3} className="mockup19 mockupAnimation" alt="" />
        <img src={mck2} className="mockup20 mockupAnimation" alt="" />
        <img src={mck} className="mockup21 mockupAnimation" alt="" />
      </div>
    </div>
  );
};

export default Sec3Trans4;
