import React from "react";
import "./Page.css";

import PageRow from "./PageRow";
import { PagesContentArray } from "./content/PagesContentArray";

const PageEthicsAndCompliance = () => {
  return (
    <div className="Page">
      <div className="PageTitle">
        {PagesContentArray.ethicsCompliance.title}
      </div>
      <div className="PageContentColumn">
        {PagesContentArray.ethicsCompliance.contentArray.map((id, i) => {
          return (
            <PageRow
              key={PagesContentArray.ethicsCompliance.contentArray[i].id + i}
              id={PagesContentArray.ethicsCompliance.contentArray[i].id}
              parentAppPage={PagesContentArray.ethicsCompliance.appPage}
              hidden={PagesContentArray.ethicsCompliance.contentArray[i].hidden}
              columnNo={
                PagesContentArray.ethicsCompliance.contentArray[i].columnNo
              }
              form={PagesContentArray.ethicsCompliance.contentArray[i].form}
              button={PagesContentArray.ethicsCompliance.contentArray[i].button}
              title={PagesContentArray.ethicsCompliance.contentArray[i].title}
              link={PagesContentArray.ethicsCompliance.contentArray[i].link}
              img={PagesContentArray.ethicsCompliance.contentArray[i].img}
              text={PagesContentArray.ethicsCompliance.contentArray[i].text}
              option={PagesContentArray.ethicsCompliance.contentArray[i].option}
              imgUrl={PagesContentArray.ethicsCompliance.contentArray[i].imgUrl}
              linkUrl={
                PagesContentArray.ethicsCompliance.contentArray[i].linkUrl
              }
            />
          );
        })}
      </div>
    </div>
  );
};
export default PageEthicsAndCompliance;
