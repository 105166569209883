import React from "react";
import Sec1 from "../components/Sec1/Sec1";
import Sec2 from "../components/Sec2/Sec2";
import Sec2Trans3 from "../components/Sec2Trans3/Sec2Trans3";
import Sec3 from "../components/Sec3/Sec3";
import Sec3Trans4 from "../components/Sec3Trans4/Sec3Trans4";
import Sec4 from "../components/Sec4/Sec4";
import Sec5 from "../components/Sec5/Sec5";
import Sec7 from "../components/Sec7/Sec7";

const Home = () => {
  return (
    <div>
      <Sec1 />
      <Sec2 />
      <Sec2Trans3 />
      <Sec3 />
      <Sec3Trans4 />
      <Sec4 />
      <Sec5 />
      <Sec7 />
    </div>
  );
};

export default Home;
