import React from "react";

import "./Footer.css";
import Logo from "../../assets/images/Logo.svg";

const Footer = () => (
  <footer>
    <div className="mainFooter">
      <div className="footerPart1">
        <a style={{ width: "fit-content", textDecoration: "none" }}>
          dill Guest App
        </a>
        <a style={{ width: "fit-content" }}>dill Manager App</a>
        <a style={{ width: "fit-content" }}>dill Server App</a>
        <a href="/#section2" style={{ width: "fit-content" }}>
          About dill
        </a>
        <a style={{ width: "fit-content" }}>Events</a>
        <img className="logoFoot" src={Logo} alt="dil Logo" />
      </div>
      <div className="footerPart2">
        <a
          href="/BookVisit"
          style={{ width: "fit-content", textDecoration: "none" }}
        >
          Book Visit
        </a>
        <a href="/Support" style={{ width: "fit-content" }}>
          Support
        </a>
        <a href="/Investors" style={{ width: "fit-content" }}>
          Investors
        </a>
        <a href="/Partners" style={{ width: "fit-content" }}>
          Partners
        </a>
        <a href="/Contact" style={{ width: "fit-content" }}>
          Contact
        </a>
        <a href="/Careers" style={{ width: "fit-content" }}>
          Careers
        </a>
      </div>
      <div className="footerPart3">
        <a href="/PrivacyPolicy" style={{ width: "fit-content" }}>
          Privacy Policy
        </a>
        <a href="/TermsOfUse" style={{ width: "fit-content" }}>
          Terms of Use
        </a>
        <a href="/Legal" style={{ width: "fit-content" }}>
          Legal
        </a>
      </div>
      <div className="footerPart4">
        <a href="/InclusionAndDiversity" style={{ width: "fit-content" }}>
          Inclusion and Diversity
        </a>
        <a href="/Environment" style={{ width: "fit-content" }}>
          Environment
        </a>
        <a href="/Privacy" style={{ width: "fit-content" }}>
          Privacy
        </a>
        <a href="/EthicsAndCompliance" style={{ width: "fit-content" }}>
          Ethics and Compliance
        </a>
      </div>
    </div>

    <div className="row">
      <p style={{ color: "#e3ccc5" }}>
        Copyright &copy; 2022 by dill Tech. All rights reserved.
      </p>
    </div>
  </footer>
);

export default Footer;
