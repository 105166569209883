import React from "react";
import Navigation2 from "../components/Navigation/Navigation2";
import PageEthicsAndCompliance from "../components/PageEthicsAndCompliance";

const EthicsAndCompliance = () => {
  return (
    <div>
      <Navigation2 />
      <PageEthicsAndCompliance />
    </div>
  );
};

export default EthicsAndCompliance;
