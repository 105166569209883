import React from "react";
import Navigation2 from "../components/Navigation/Navigation2";

import PageSupport from "../components/PageSupport";
const Support = () => {
  return (
    <div>
      <Navigation2 />
      <PageSupport />
    </div>
  );
};

export default Support;
