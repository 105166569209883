import React from "react";
import Navigation2 from "../components/Navigation/Navigation2";
import PageToU from "../components/PageToU";
const TermsOfUse = () => {
  return (
    <div>
      <Navigation2 />
      <PageToU />
    </div>
  );
};

export default TermsOfUse;
