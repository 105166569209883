import React, { Component } from "react";
import Logo1 from "../../assets/images/Logo.svg";
import Logo2 from "../../assets/images/Logo-2.svg";
import "./Navigation.css";
class NavigationMobile extends Component {
  state = {
    displayNav: false,
    style: "main-navm hidden",
    icon: "menu",
  };

  menuClickHandler = () => {
    if (this.state.displayNav === false) {
      this.setState({
        displayNav: true,
        style: "main-navm show",
        icon: "close",
      });
    } else {
      this.setState({
        displayNav: false,
        style: "main-navm hidden",
        icon: "menu",
      });
    }
  };

  render() {
    return (
      <div className="nav-container">
        <nav className={"notSticky"}>
          <div className="logo-container">
            <a href="/#">
              <img className="logo" src={Logo2} alt="dil Logo" />
              <img
                className="logo-black"
                src={Logo1}
                alt="dill Logo"
                style={{ marginLeft: 30 }}
              />
            </a>
          </div>
          <ul className={"main-nav show"}>
            <li>
              <div className="navigation-ScheduleAppointment2">
                <a
                  href="/BookVisit"
                  style={{
                    textTransform: "none",
                  }}
                >
                  Schedule Appointment
                </a>
              </div>
            </li>
          </ul>

          <ul className={this.state.style}>
            <li style={{ marginBottom: 20, backgorund: "red" }}>
              <a className="mobile-nav-icon2" onClick={this.menuClickHandler}>
                <ion-icon name={this.state.icon}></ion-icon>
              </a>
            </li>

            <li>
              <a
                style={{ textTransform: "none", marginRight: "10vh" }}
                href="/#aboutUs"
              >
                About us
              </a>
            </li>
            <li>
              <a
                style={{
                  textTransform: "none",
                  marginRight: "10vh",
                }}
                href="/Support"
              >
                Support
              </a>
            </li>
            <li>
              <a
                style={{ textTransform: "none", marginRight: "10vh" }}
                href="/#joining"
              >
                Joining
              </a>
            </li>
            <li>
              <a
                style={{ textTransform: "none", marginRight: "10vh" }}
                href="/#pricing"
              >
                Pricing
              </a>
            </li>
            <li>
              <div className="navigation-ScheduleAppointment">
                <a
                  className="navigation-ScheduleAppointment"
                  href="/BookVisit"
                  style={{
                    textTransform: "none",
                  }}
                >
                  Schedule Appointment
                </a>
              </div>
            </li>
          </ul>

          <a className="mobile-nav-icon" onClick={this.menuClickHandler}>
            <ion-icon name={this.state.icon}></ion-icon>
          </a>
        </nav>
      </div>
    );
  }
}

export default NavigationMobile;
