import React from 'react';
import './Page.css';
import { Link } from "react-router-dom";

const PageRowItem = ({ id, hidden, form, button, title, link, img, text, option, imgUrl, linkUrl }) => {
    if (!hidden) {
        if (form) {
            if (option) {
                return(
                    <textarea id={id} className='InputBox SpecialInputBox LargeBox' type="text" placeholder={text} required="" name={id}/>
                )
            } else {
                return(
                    <input id={id} className='InputBox SpecialInputBox' type="text" placeholder={text} required="" name={id}/>
                )
            }
        } else if (button){
            return(
                <button className="FormButton">{text}</button>
            )
        } else if (title){
            return (
                <div className="SectionTitle">{text}</div>
            )
        } else if (img) {
            if (link) {
                if (link === 1) {
                    return (
                        <Link to={linkUrl}><img className='PageRowIcon' src={imgUrl} alt={id} /></Link>
                    )
                } else if (link > 1) {
                    return (
                        <a href={linkUrl}><img className='PageRowIcon' src={imgUrl} alt={id} /></a>
                    )
                } else {
                    return null
                }
            } else if (!link){
                return (
                    <div><img className='PageRowIcon' src={imgUrl} alt={id} /></div>
                )
            }
        } else {
            if (link) {
                if (link === 1) {
                    return (
                        <Link to={linkUrl} className="PageRowText">{text}</Link>
                    )
                } else if (link > 1) {
                    return (
                        <a href={linkUrl} className="PageRowText">{text}</a>
                    )
                } else {
                    return null
                }
            } else if (!link){
                return (
                    <div className="PageRowText">{text}</div>
                )
            }
        }
    } else {
        return(
            null
        )
    }
}

export default PageRowItem;