import React from "react";

import Navigation2 from "../components/Navigation/Navigation2";
import PageInvestors from "../components/PageInvestors";
const Investors = () => {
  return (
    <div>
      <Navigation2 />
      <PageInvestors />
    </div>
  );
};

export default Investors;
