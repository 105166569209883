import React from "react";
import "./Sec4Trans5.css";
import imageOne from "../../assets/images/sec4to5image1.png";
import imageTwo from "../../assets/images/sec4to5image2.png";

const Sec4Trans5 = (props) => {
  return (
    <div id={""}>
      <div className="section4to5-container">
        <div className="section4to5-main-container">
          <div className="section4to5-image-container">
            <div className="section4to5-image-container">
              <div className="section4to5-text-part1">
                <img
                  className="section4to5-image1"
                  src={imageOne}
                  alt="sec45-1"
                />
                <h3>Joining the dill network</h3>
              </div>
            </div>
            <img className="section4to5-image2" src={imageTwo} alt="sec45-2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sec4Trans5;
