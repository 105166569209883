import React from "react";

import Navigation2 from "../components/Navigation/Navigation2";
import PagePartners from "../components/PagePartners";
const Partners = () => {
  return (
    <div>
      <Navigation2 />
      <PagePartners />
    </div>
  );
};

export default Partners;
