import React, { useEffect, useState } from "react";
import "./Sec3.css";
import imageOne from "../../assets/images/sec3second.png";
import imageThree from "../../assets/images/2chef.png";

const Sec3 = (props) => {
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      setTimeout(() => {}, 20);
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <div id={"aboutUs"}>
      {windowSize.innerWidth <= 500 ? (
        <div className="section3-part2-m-container">
          <div className="section3-part2-m-subcontainer">
            <div className="sec3-2-box1-m-container">
              <div className="sec3-2box1-m">
                <div className="sec3-2box1-inner-box-m">
                  <ul>
                    <li>
                      dill automates restaurant owners' tedious and awkward tasks so they can focus on what makes their restaurant special.
                    </li>
                    <li>
                      Informed decisions made in time improves guest experience, increases revenue, and reduces stress and costs.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="sec3-2-box2-container-m">
              <div className="sec3-2-box2-m">
                <div className="sec3-2-box2-inner-box-m">
                  <div className="sec3-2-box2-inner-box-text-m">
                    <a>
                      Let us take care of some of the load so you can be your creative self
                    </a>
                    <ul>
                      <li>We can modernize your tech setup seamlessly</li>
                      <li>
                        We can automate a lot of your tedious tasks like accounting and inventory
                      </li>
                      <li>
                        We can automate a lot of your awkward tasks like staff performance monitoring and giving negative feedback
                      </li>
                    </ul>
                    <div className="learn-more-1">
                      <a href="/BookVisit">Learn more</a>
                    </div>
                  </div>
                </div>
                <img src={imageThree} className="sec3-2-box2-image-m" alt="" />
              </div>
            </div>

            <div className="sec3-2-box3-container">
              <div className="sec3-2-box3">
                <div className="sec3-2-box3-innerBox-1">
                  <div className="sec3-2-box3-innerBox-1-textPart">
                    <a>Let us increase your revenue and reduce your costs</a>
                    <ul>
                      <li>Increasing revenue by</li>
                    </ul>
                    <ul className="inner-ul">
                      <li>
                        Reaching more customers through our platform especially with offers
                      </li>
                      <li>
                        Improving customer experience and ratings will bring in more business
                      </li>
                    </ul>
                    <ul>
                      <li>Decreasing expenses by reducing:</li>
                    </ul>
                    <ul className="inner-ul">
                      <li>Tech costs</li>
                      <li>Food costs</li>
                      <li>Labor costs</li>
                    </ul>
                  </div>
                  <div className="learn-more-2">
                    <a href="/BookVisit">Learn more</a>
                  </div>
                </div>
                <div className="sec3-2-box3-innerBox-2">
                  <div className="sec3-2-box3-innerBox-2-textPart">
                    <a>
                      Let us keep you informed, on the pulse, and relaxed. You’re always in control
                    </a>
                    <ul>
                      <li>About what’s going on in your restaurant</li>
                      <li>
                        About what needs to improve in your restaurant and how to do it
                      </li>
                      <li>
                        About what’s going on in the industry and with the customers
                      </li>
                    </ul>
                  </div>
                  <div className="learn-more-3">
                    <a href="/BookVisit">Learn more</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="sec3-2-box4-container-m">
              <div className="sec3-2-box4-m">
                <div className="sec3-2-box4-innerBox-m">
                  <a>We will help you cope with the staff shortage</a>

                  <ul>
                    <li>Retaining your staff</li>
                    <li>Making your staff more efficient/productive</li>
                    <li>
                      Finding new employees on our platform with employer recommendations
                    </li>
                    <li>Get temporary help on particular shifts</li>
                  </ul>
                </div>
                <div className="learn-more-4">
                  <a href="/BookVisit">Learn more</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="section3-part2-container">
          <div className="section3-part2-subcontainer">
            <div className="sec3-2-box1-container">
              <div className="sec3-2box1">
                <div className="sec3-2box1-inner-box">
                  <ul>
                    <li>
                      dill automates restaurant owners' tedious and awkward tasks so they can focus on what makes their restaurant special.
                    </li>
                    <li>
                      Informed decisions made in time improves guest experience, increases revenue, and reduces stress and costs.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="sec3-2-box2-container">
              <div className="sec3-2-box2">
                <div className="sec3-2-box2-inner-box">
                  <div className="sec3-2-box2-inner-box-text">
                    <a>
                      Let us take care of some of the load so you can be your creative self
                    </a>
                    <ul>
                      <li>We can modernize your tech setup seamlessly</li>
                      <li>
                        We can automate a lot of your tedious tasks like accounting and inventory
                      </li>
                      <li>
                        We can automate a lot of your awkward tasks like staff performance monitoring and giving negative feedback
                      </li>
                    </ul>
                    <div className="learn-more-1">
                      <a href="/BookVisit">Learn more</a>
                    </div>
                  </div>
                </div>
                <img src={imageOne} className="sec3-2-box2-image" alt="" />
              </div>
            </div>
            <div className="sec3-2-box3-container">
              <div className="sec3-2-box3">
                <div className="sec3-2-box3-innerBox-1">
                  <div className="sec3-2-box3-innerBox-1-textPart">
                    <a>Let us increase your revenue and reduce your costs</a>
                    <ul>
                      <li>Increasing revenue by:</li>
                    </ul>
                    <ul className="inner-ul">
                      <li>
                        Reaching more customers through our platform especially with offers 
                      </li>
                      <li>
                        Improving customer experience and ratings will bring in more business
                      </li>
                    </ul>
                    <ul>
                      <li>Decreasing expenses by reducing:</li>
                    </ul>
                    <ul className="inner-ul">
                      <li>Tech costs</li>
                      <li>Food costs</li>
                      <li>Labor costs</li>
                    </ul>
                  </div>
                  <div className="learn-more-2">
                    <a href="/BookVisit">Learn more</a>
                  </div>
                </div>
                <div className="sec3-2-box3-innerBox-2">
                  <div className="sec3-2-box3-innerBox-2-textPart">
                    <a>
                      Let us keep you informed, on the pulse, and relaxed. You’re always in control
                    </a>
                    <ul>
                      <li>About what’s going on in your restaurant</li>
                      <li>
                        About what needs to improve in your restaurant and how to do it
                      </li>
                      <li>
                        About what’s going on in the industry and with the customers
                      </li>
                    </ul>
                  </div>
                  <div className="learn-more-2">
                    <a href="/BookVisit">Learn more</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="sec3-2-box4-container">
              <div className="sec3-2-box4">
                <div className="sec3-2-box4-innerBox">
                  <a>We will help you cope with the staff shortage</a>

                  <ul>
                    <li>Retaining your staff</li>
                    <li>Making your staff more efficient/productive</li>
                    <li>
                      Finding new employees on our platform with employer recommendations
                    </li>
                    <li>Get temporary help on particular shifts</li>
                  </ul>
                </div>
                <div className="learn-more-last">
                  <a href="/BookVisit">Learn more</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sec3;
