import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home";
import TermsOfUse from "./pages/TermsOfUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Legal from "./pages/Legal";
import InclusionAndDiversity from "./pages/InclusionAndDiversity";
import Environment from "./pages/Environment";
import Privacy from "./pages/Privacy";
import EthicsAndCompliance from "./pages/EthicsAndCompliance";
import BookAVisit from "./pages/BookAVisit";
import Support from "./pages/Support";
import Investors from "./pages/Investors";
import Partners from "./pages/Partners";
import Contact from "./pages/Contact";
import Careers from "./pages/Careers";
function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/TermsOfUse" element={<TermsOfUse />} />
        <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route exact path="/Legal" element={<Legal />} />
        <Route
          exact
          path="/InclusionAndDiversity"
          element={<InclusionAndDiversity />}
        />
        <Route exact path="/Environment" element={<Environment />} />
        <Route exact path="/Privacy" element={<Privacy />} />
        <Route
          exact
          path="/EthicsAndCompliance"
          element={<EthicsAndCompliance />}
        />
        <Route exact path="/BookVisit" element={<BookAVisit />} />
        <Route exact path="/Support" element={<Support />} />
        <Route exact path="/Investors" element={<Investors />} />
        <Route exact path="/Partners" element={<Partners />} />
        <Route exact path="/Contact" element={<Contact />} />
        <Route exact path="/Careers" element={<Careers />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
