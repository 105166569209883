import React from "react";
import Navigation2 from "../components/Navigation/Navigation2";
import PageBookAVisit from "../components/PageBookAVisit";

const BookAVisit = () => {
  return (
    <div>
      <Navigation2 />
      <PageBookAVisit />
    </div>
  );
};

export default BookAVisit;
