import React from "react";
import Navigation2 from "../components/Navigation/Navigation2";

import PagePrivacy from "../components/PagePrivacy";
const Privacy = () => {
  return (
    <div>
      <Navigation2 />
      <PagePrivacy />
    </div>
  );
};

export default Privacy;
