import React from "react";
import Navigation2 from "../components/Navigation/Navigation2";

import PageEnvironment from "../components/PageEnvironment";
const Environment = () => {
  return (
    <div>
      <Navigation2 />
      <PageEnvironment />
    </div>
  );
};

export default Environment;
